export default [
  {
    nombres: "Greco",
    apellidos: "Maldonado",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Analista de Infraestructura TI Senior",
    frase: "Persistir",
  },
  {
    nombres: "Yasmin",
    apellidos: "Alvarado",
    foto: require("../../assets/images/nosotros_equipo/equipo/14.png"),
    //puesto: "Administrative Analyst",
    frase: "Empatía",
  },
  {
    nombres: "Javier",
    apellidos: "Blanco",
    foto: require("../../assets/images/nosotros_equipo/equipo/11.png"),
    //puesto: "Cloud Full Stack Developer",
    frase: "Perseverancia",
  },
  {
    nombres: "Jorge",
    apellidos: "Salcedo",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Cloud Solutions Architect",
    frase: "Entrega",
  },
  {
    nombres: "Erick",
    apellidos: "Sánchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/22.png"),
    //puesto: "Analista de Infraestuctura",
    frase: "Constancia",
  },
  {
    nombres: "Gerardo",
    apellidos: "Navarro",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Administrador de Base de Datos",
    frase: "Dedicación",
  },
  {
    nombres: "Martin",
    apellidos: "Maras",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: "Administrador de Base de Datos",
    frase: "Empeño",
  },
  {
    nombres: "María",
    apellidos: "Riojas",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "Cloud Developer Junior",
    frase: "Resiliencia",
  },
  {
    nombres: "Kevin",
    apellidos: "Hañari",
    foto: require("../../assets/images/nosotros_equipo/equipo/7.png"),
    //puesto: "Operador de TI",
    frase: "Perseverancia",
  },
  {
    nombres: "Pamela",
    apellidos: "Alvarado",
    foto: require("../../assets/images/nosotros_equipo/equipo/15.png"),
    //puesto: "Cloud Especialist Junior",
    frase: "Empatía",
  },
  {
    nombres: "Erson",
    apellidos: "Jacobo",
    foto: require("../../assets/images/nosotros_equipo/equipo/2.png"),
    //puesto: "Cloud Specialist Junior",
    frase: "Comprensión",
  },
  {
    nombres: "Carlos",
    apellidos: "Salinas",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: "Operador de TI",
    frase: "Determinación",
  },
  {
    nombres: "Persy",
    apellidos: "Paredes",
    foto: require("../../assets/images/nosotros_equipo/equipo/16.png"),
    //puesto: "Analista de Infraestructura TI",
    frase: "Persistencia",
  },
  {
    nombres: "Jose",
    apellidos: "Zegarra",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: "Ingeniero DevOps Junior",
    frase: "Innovador",
  },
  {
    nombres: "Florencia",
    apellidos: "Pasihuan",
    foto: require("../../assets/images/nosotros_equipo/equipo/30.png"),
    //puesto: "",
    frase: "Constante",
  },
  {
    nombres: "Bryan",
    apellidos: "Vera",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "",
    frase: "Nobleza",
  },
  {
    nombres: "Robert",
    apellidos: "Sanchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/27.png"),
    //puesto: "",
    frase: "Perseverante",
  },
  {
    nombres: "Sofía",
    apellidos: "Pisfil",
    foto: require("../../assets/images/nosotros_equipo/equipo/25.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Equilibrio",
  },
  {
    nombres: "Mireya",
    apellidos: "La Rosa",
    foto: require("../../assets/images/nosotros_equipo/equipo/13.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Optimismo",
  },
  {
    nombres: "Alan",
     apellidos: "Carlos",
     foto: require("../../assets/images/nosotros_equipo/equipo/25.png"),
     //puesto: " Administradora de Base de Datos",
     frase: "Equilibrio",
  },
  {
    nombres: "Jorge",
    apellidos: "Rodriguez",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Visionario",
  },
  {
    nombres: "Daniel",
    apellidos: "Daza",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: " Administradora de Base de Datos",
    frase: "Compromiso",
  },
  {
    nombres: "Jesus",
    apellidos: "Pumacayo",
    foto: require("../../assets/images/nosotros_equipo/equipo/18.png"),
    //puesto: "IR",
    frase: "Determinación",
  },
  {
    nombres: "Ray",
    apellidos: "Baca",
    foto: require("../../assets/images/nosotros_equipo/equipo/2.png"),
    //puesto: "IR",
    frase: "Perseverante",
  },
  {
    nombres: "Jennifer",
    apellidos: "Solis",
    foto: require("../../assets/images/nosotros_equipo/equipo/31.png"),
    //puesto: "Developer Trainee",
    frase: "Progresar",
  },
  {
    nombres: "Jean",
    apellidos: "Vargas",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "",
    frase: "Tenaz",
  },
  {
    nombres: "Melissa",
    apellidos: "Nores",
    foto: require("../../assets/images/nosotros_equipo/equipo/30.png"),
    //puesto: "",
    frase: "Paciencia",
  },
  {
    nombres: "Ivan",
    apellidos: "Diaz",
    foto: require("../../assets/images/nosotros_equipo/equipo/26.png"),
    //puesto: "",
    frase: "Innovador",
  },
  {
    nombres: "Ashley",
    apellidos: "Villanueva",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "",
    frase: "Despegar",
  },
  {
    nombres: "Roy",
    apellidos: "Sandoval",
    foto: require("../../assets/images/nosotros_equipo/equipo/7.png"),
    //puesto: "Operador TI Developer",
    frase: "Instinto",
  },
  {
    nombres: "Julio",
    apellidos: "Vargas",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "",
    frase: "Líder",
  },
  {
    nombres: "Francis",
    apellidos: "Hidalgo",
    foto: require("../../assets/images/nosotros_equipo/equipo/6.png"),
    //puesto: "",
    frase: "Resiliencia",
  },
  {
    nombres: "Diego",
    apellidos: "Arotinco",
    foto: require("../../assets/images/nosotros_equipo/equipo/17.png"),
    //puesto: "",
    frase: "Proactivo",
  },
  {
    nombres: "Danny",
    apellidos: "Julca",
    foto: require("../../assets/images/nosotros_equipo/equipo/5.png"),
    //puesto: "Equipo IR",
    frase: "Determinación",
  },
  {
    nombres: "David",
    apellidos: "Gallo",
    foto: require("../../assets/images/nosotros_equipo/equipo/33.png"),
    //puesto: "Cloud Trainee",
    frase: "Curioso",
  },
  {
    nombres: "Sebastian",
    apellidos: "Ching",
    foto: require("../../assets/images/nosotros_equipo/equipo/28.png"),
    //puesto: "Cloud and Database Trainee",
    frase: "Empatía",
  },
  {
    nombres: "Dafne",
    apellidos: "Cuvas",
    foto: require("../../assets/images/nosotros_equipo/equipo/30.png"),
    //puesto: "Cloud and Database Trainee",
    frase: "Curiosidad",
  },
  {
    nombres: "Yosi",
    apellidos: "Cubas",
    foto: require("../../assets/images/nosotros_equipo/equipo/13.png"),
    //puesto: "Cloud and Database Trainee",
    frase: "Resiliente",
  },
  {
    nombres: "Giantory",
    apellidos: "Espino",
    foto: require("../../assets/images/nosotros_equipo/equipo/3.png"),
    frase: "Estrategia",
  },
  {
    nombres: "Jaime",
    apellidos: "Sánchez",
    foto: require("../../assets/images/nosotros_equipo/equipo/23.png"),
    //puesto: "Líder de Tecnología y Negocios",
    frase: "Crear",
  },
];